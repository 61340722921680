#okta-sign-in {
  min-height: 625px;
  width: 325px !important;
  padding: 40px;
  display: flex;
  flex-direction: column;
  margin: auto;
  border-radius: 4px;
}

#okta-sign-in.auth-container {
  width: auto;
  margin-right: auto;
  margin-left: auto;
}

#okta-sign-in.auth-container.main-container {
  box-shadow: inset 0 1.75px 1.75px #ffffff;
  border: 2.5px solid #e3e3e3;
}

#sign-in-widget * {
  margin-top: 5%;
  margin-bottom: 5%;
  font-family: Roboto, sans-serif !important;
}

.oktaLink {
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-family: Roboto, sans-serif;
  font-size: smaller;
}

.oktaLink:hover {
  cursor: pointer;
}

#okta-signin-submit {
  font-size: calc(3px + 2vmin) !important;
  background: #007bff !important;
  border: none !important;
  width: 50% !important;
  margin: auto;
}

#okta-sign-in .auth-content {
  padding: 0 0 0 0 !important;
}

.okta-form-title {
  font-size: 2rem !important;
}

.patentText {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-family: Roboto, sans-serif;
  font-size: x-small;
}

html {
  min-height: 100vh;
  background-color: rgb(250, 250, 250);
}

.App {
  background-color: rgb(250, 250, 250);
}

#okta-sign-in .auth-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#okta-sign-in .auth-org-logo {
  max-height: none !important;
}
