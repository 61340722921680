.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar-brand {
  color: white !important;
}

.navbar .container {
  width: 100%;
}

.navbar {
  border-radius: 0 !important;
}

/* App Selector CSS*/
.popover {
  max-width: none !important;
}

.services-row * {
  text-align: center;
}

.service-column:hover {
  border-style: solid;
  border-color: lightgrey;
  cursor: pointer;
}

.service-column {
  margin-bottom: 5px;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  padding: 3px 3px 3px 3px;
}

.app-grid-button {
  color: #9d9d9d;
}

.app-grid-button:hover {
  cursor: pointer;
  color: white;
}

.disabledStyle {
  color: darkgray !important;
  background: #c0c0c0 !important;
  text-decoration-line: line-through;
  width: 100px;
  height: 100px;
  margin: 1%;
  border-color: gray !important;
}

.enabledStyle {
  width: 100px;
  height: 100px;
  margin: 1%;
  background: #ffffff !important;
  color: #000000 !important;
  border-color: black !important;
}
