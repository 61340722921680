.canvas {
  width: 15px;
  height: 15px;
  z-index: 8;
}

.confirmAlert {
  font-size: 2em;
  font-weight: bold;
  color: red;
}
